.contact {
  display: flex;
  align-items: center;
  padding: 1.5% 10%;
  background: transparent;
  background-color: white;
  background-image: url(../../Utils/alphawifi-background.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 50%;
}

.contact-container {
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.contact-info {
  color: #333333;
  gap: 1.5rem;
  list-style: none;
  transition: all 0.3s ease-in-out;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-decoration: none;
  text-align: left;
  margin-left: auto;
  padding-right: 10%;
  font-size: 1.2rem;
}


@media (max-width: 1000px) {
  .contact {
    display: block;
    align-items: center;
    padding: 0%;
    padding-top: 7.5%;
    background-color: navy;
    background: transparent;
    background-image: none;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 75%;
  }

  .contact-info {
    font-size: 1rem;
    text-align: center;
  }
}