.home-section {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    text-align: left;
    background: linear-gradient(to right, #262626, #4d4d4d);
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 5%;
}

.home-section h1 {
  font-size: 10rem;
  font-family: Helvetica, sans-serif;
  font-weight: bold;
  color: white;
  width: 80%;
}

.home-section p {
  font-size: 4rem;
  font-family: Helvetica, sans-serif;
  color: white;
  width: 60%;
}



@media (max-width: 950px) {
  .home-section {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    text-align: left;
    color: #333;
    padding-bottom: 5rem;
  }

  .home-section h1 {
    font-size: 5rem;
    font-family: Helvetica, sans-serif;
    font-weight: bold;
    color: white;
    width: 100%;
  }

  .home-section p {
    font-size: 1.75rem;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    max-width: 600px;
    width: 100%;
  }
}