.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background: transparent;
    background-color: black;
}

.navbar-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.navbar-title {
    color: #2998e2;
    font-size: 1.5rem;
    text-decoration: none;
    font-family: Impact, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: #2998e2;
}

.nav-links {
    color: black;
    display: flex;
    gap: 2rem;
    list-style: none;
    transition: all 0.3s ease-in-out;
    font-family: sans-serif;
    text-decoration: none;
    text-align: center;
    margin-left: auto;
    padding-right: 1%;
}

.nav-links a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 2px;
}

.nav-links a:hover {
    color: #2998e2;
    text-decoration: underline;
  }

@media (max-width: 768px) {
    .navbar {
        margin: auto;
    }

    .navbar-title {
        display: none;
    }

    .nav-links {
        gap: 1rem;
    }
    
    .nav-links a {
        text-decoration: none;
        color: white;
        font-weight: bold;
        letter-spacing: 1px;
    }
}
