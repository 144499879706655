.service-card {
    padding: 20px;
    width: 15rem;
    height: 10rem;
    text-align: center;
    border-radius: 20px;
    background: transparent;
  }

.service-card h3 {
  font-size: 1.75rem;
  color: #262626;
  margin-bottom: 20px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.service-card p {
  font-family: 'Arial, sans-serif';
  font-style: italic;
  font-size: 1.25rem;
  color: #262626;
  margin-bottom: 20px;
  width: 15rem;
}

@media (max-width: 768px) {
  .service-card {
    padding: 0px;
    width: 17.5rem;
    height: 7rem;
    text-align: center;
    background: transparent;
    border-radius: 0%;
  }

  .service-card h3 {
    margin-bottom: 0px;
    font-size: 1.35rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  .service-card p {
    font-family: 'Arial, sans-serif';
    font-style: italic;
    font-size: 1.10rem;
    width: 17.5rem;
    margin-bottom: 0%;
  }
}