.contact-form-container {
    background: white;
    border-radius: 8px;
    text-align: center;
    padding-bottom: 2.5rem;
  }
  
  .contact-form-container h2 {
    font-size: 9rem;
    margin-bottom: 5rem;
    color: #333333;
  }
  
  .contact-form {
    width: 60%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .contact-form label {
    width: 100%;
    font-size: 1.15rem;
    color: #333333;
    font-weight: bold;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .contact-form textarea {
    resize: vertical;
    min-height: 100px;
    width: 100%;
  }
  
  .contact-form button {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  
  .success-message {
    color: green;
    text-align: center;
    margin-bottom: 10px;
  }

  @media (max-width: 1000px) {
    .contact-form {
      width: 70%;
    }

    .contact-form-container h2 {
        font-size: 3rem;
    }

    .contact-form label {
        font-size: 1rem;
    }

    .contact-form textarea {
        min-height: 150px;
    }

    .contact-form-container h2 {
        margin-bottom: 2.5rem;
      }
  }