.App {
  justify-content: space-between;
  align-items: center;
  background: white;
}

.App-home {
  justify-content: space-between;
  background: white;
  background-image: url('./Utils/awifi-logo.png');
  background-repeat: no-repeat;
  background-size: 35%;
  height: 120vh;
  width: 100%;
  background-position: calc(100% - 15rem);
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

.App-title {
  font-size: calc(100px);
  color: #12b9e7;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-weight: bold;
}

.App-body {
  background-color: gray;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 200px;
}

.App-link {
  color: #12b9e7;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.desc {
  color: #000000;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bullet {
  color: #000000;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.title {
  color: #010708;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}


.App-link-number {
  color: #16bbe9;
  font-size: 40px;
  font-weight: bold;
}

.Section {
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

@media (max-width: 768px) {
  .Section {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 60px;
      right: 10px;
      background-color: #7db6eb;
      padding: 1rem;
      border-radius: 8px;
  }

  .menu-toggle {
      display: block;
  }
}