.services-section {
    display: flex;
    flex-direction: column;
    padding: 60px;
    background: linear-gradient(to right, #666666, #999999);
    padding-left: 10%;
    padding-right: 10%;
}

.section-display {
  display: flex;
}
  
.services-section h1 {
  font-size: 8rem;
  font-family: Helvetica, sans-serif;
  font-weight: bold;
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  text-decoration: none;
  text-shadow: '2px 2px 5px rgba(0, 0, 0, 0.5)';
}

.services-section h3 {
  font-weight: bolder;
  font-size: 4rem;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.services-left {
  font-size: 1.75rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color: white;
  max-width: 700px;
  align-items: center;
  text-align: left;  
  justify-content: left;
  padding-right: 3%;
  width: 50rem;
  padding-left: 7%;
}

.custom-divider {
  border: none;
  height: 2px;
  background-color: antiquewhite;
  margin: 20px 0;
}

.services-section h2 {
  font-size: 1.75rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color: white;  
  justify-content: center;
  align-items: center;
  text-align: center;
}

.services-right {
  font-size: 1.75rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color: white;
  max-width: 700px;
  width: 50rem;
  align-items: left;
  text-align: left;
  justify-content: right;
  margin-left: auto;
  padding-right: 7%;
}

@media screen and (max-width: 950px) {
  .services-section {
    display: flex;
    padding: 00px;
    background: linear-gradient(to right, #666666, #999999);
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .services-section h1 {
    font-size: 3.75rem;
  }
  
  .section-display {
    display: block;
    padding-top: 0%;
    padding-bottom: 0%;
  }

  .services-section h3 {
    font-weight: bolder;
    font-size: 1.5rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }

  .services-left {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: white;
    max-width: 950px;
    align-items: center;
    text-align: center;  
    justify-content: left;
    padding-right: 0%;
    width: auto;
    padding-left: 0%;
  }

  .services-right {
    font-size: 1rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: white;
    max-width: 950px;
    width: auto;
    align-items: left;
    text-align: left;
    justify-content: left;
    margin-left: 0%;
    padding-right: 0%;
  }

  .services-section h2 {
    font-size: 1.1rem;
  }
}