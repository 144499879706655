.services-page {
    text-align: center;
    padding-top: 1.5rem;
    padding-bottom: 60px;
    background-color: antiquewhite;
  }

.services-page h1 {
  font-size: 9rem;
  color: #262626;
  font-family: Helvetica, sans-serif;
}
  
.services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service-icon {
  width: 50px;
  margin-bottom: 10px;
}

.services-divider {
  border: none;
  height: 2px;
  background-color: #262626;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 60px;
}

@media (max-width: 950px) {
  .services-page {
    text-align: center;
    padding-top: 1.5rem;
    background-color: antiquewhite;
    padding-bottom: 20px;
  }

  .services-divider {
    margin-bottom: 10px;
    margin-left: 5%;
    margin-right: 5%;
  }

  .services-page h1 {
    font-size: 3rem;
  }
    
  .services-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0%;
  }

  .service-icon {
    width: 50px;
  }
}